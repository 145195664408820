const URL_FILTERS = process.env.REACT_APP_URL_FILTERS;

// список фильтров с сервера
export const getFilter = () => {
    const dataFilters = () => {
        const savedFilter = localStorage.getItem("filter");
        const savedTime = localStorage.getItem("filterTime");
        const currentTime = new Date().getTime();
        // время хранения фильтров 12 часов в миллисекундах
        const filterCachingDuration = 12 * 60 * 60 * 1000;

        if (
            savedFilter &&
            savedTime &&
            currentTime - savedTime <= filterCachingDuration
        ) {
            return JSON.parse(savedFilter);
        } else {
            return getFiltersFromServer();
        }
    };

    // функция получения списка фильтров с сервера
    const getFiltersFromServer = async () => {
        try {
            const response = await fetch(`${URL_FILTERS}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            localStorage.setItem("filter", JSON.stringify(data));
            localStorage.setItem("filterTime", new Date().getTime());
            return data;
        } catch (error) {
            console.error("Ошибка при получении данных:", error);
            throw error;
        } finally {
        }
    };
    return dataFilters();
};
