import moment from "moment";

export const defaultLimit = 20;

// функция преобразования даты в нужный формат
export const formatDate = (dateString) => {
    const date = moment(dateString);
    return date.format("LLL");
};

// функция преобразования строки по регулярному выражению
export const transformFromString = (str) => {
    const tagRegex = /<[^>]*>/g;
    return str.replace(tagRegex, "");
};

// функция извлечения email отправителя
export const emailSender = (str) => {
    const result = str.match(/<([^>]+)>/);
    if (result) {
        return result[1];
    } else {
        return str;
    }
};

// обновления значения заданного ключа файла конфигурации
export const updateObj = (obj, name, value) => {
    for (let key in obj) {
        if (key === name) {
            return { [key]: value };
        }
    }
};

// конфигурация для homepage
export const configHome = {
    limit: defaultLimit,
    offset: 0,
    is_unique: true,
    hide: "empty_email",
    industry: [],
    company: [],
    country: [],
    email_id: "",
};

// функция для валидности для форматирования объекта
export const formatObj = (value) => {
    return {
        limit: Number(value.get("limit")),
        offset: Number(value.get("offset")),
        is_unique: value.get("is_unique"),
        hide: value.get("hide"),
        industry:
            value.get("industry") !== ""
                ? value.get("industry").split(",")
                : [],
        company:
            value.get("company") !== "" ? value.get("company").split(",") : [],
        country:
            value.get("country") !== "" ? value.get("country").split(",") : [],
        email_id: "",
    };
};
