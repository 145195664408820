import React from "react";
import "./singleMenu.css";
import { Link } from "react-router-dom";
import { configHome } from "../../utils/helper/Helper";

export default function SingleMenu(props) {
    const { title, updateConfig } = { ...props };

    return (
            <Link to="/">
        <div className="single__menu__container" onClick={() => updateConfig(configHome)}>
                <div className="single__menu__title__active">
                    <h4>{title}</h4>
                </div>
        </div>
            </Link>
    );
}
