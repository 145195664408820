import React from "react";
import "./blockHtml.css";
import DOMPurify from "dompurify";
import NoEmails from "../../pages/noEmails/NoEmails";

export default function BlockHtml(props) {
    const { html } = { ...props };

    // санитаризация (очистка) html от вредных последствий
    const sanitizedBody = () => ({
        __html: DOMPurify.sanitize(html.body),
    });
    const sanitizedHtml = () => ({
        __html: DOMPurify.sanitize(html.html_content),
    });

    return (
        <div className="html__container">
            {html.html_content === "" ? (
                html.body !== "" ? (
                    <div dangerouslySetInnerHTML={sanitizedBody()} />
                ) : (
                    <NoEmails data={"Email is empty"} />
                )
            ) : (
                <div dangerouslySetInnerHTML={sanitizedHtml()} />
            )}
        </div>
    );
}
