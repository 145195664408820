import React from "react";
import "./fieldFilter.css";
import { updateObj } from "../../utils/helper/Helper";
import { CloseOutlined } from "@ant-design/icons";

export default function FieldFilter(props) {
    const { config, updateConfig, title } = { ...props };
    const dataTag = config[title];

    // функция закрытия tag
    const closeTag = (item) => {
        const newTags = config[title].filter((tag) => tag !== item);
        const newObj = updateObj(config, title, newTags)
        updateConfig({...newObj, "is_unique": true});
    };

    return (
        <div className="fieldFilter__container">
            {dataTag.length > 0 ? (
                <div className="fieldFilter__items">
                    <div className="fieldFilter__items__title">
                        <h4>{title}:</h4>
                    </div>
                    {dataTag.map((tag, index) => (
                        <div className="fieldFilter__item" key={index}>
                            <div className="fieldFilter__item__tag">{tag}</div>
                            <CloseOutlined
                                className="fieldFilter__item__icon"
                                onClick={() => closeTag(tag)}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <div className="fieldFilter__items__not"></div>
            )}
        </div>
    );
}
