import React from "react";
import "./noEmails.css";
import { Empty } from "antd";
export default function NoEmails(props) {
    return (
        <div className="no__emails__container">
            <Empty description={props.data} />
        </div>
    );
}
