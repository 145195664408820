import React from "react";
import "./menu.css";
import { emailSender, transformFromString } from "../../utils/helper/Helper";
import FieldFilter from "../fieldFilter/FieldFilter";
import SingleMenu from "../singleMenu/SingleMenu";
import Filter from "./../filter/Filter";
import { Link } from "react-router-dom";

export default function Menu(props) {
    const { filters, updateConfig, config, email } = {
        ...props,
    };

    // получение имени отправителя
    const nameSender = () => {
        if (email === null) {
            return "";
        } else {
            if (email.from_email_name) {
                return email.from_email_name;
            } else {
                return transformFromString(email.from_email);
            }
        }
    };

    // функция получения всех писем компании
    const onAllEmailCompany = (value) => {
        const tempCompany = Array(emailSender(value.from_email));
        updateConfig({
            company: tempCompany,
            offset: 0,
            email_id: "",
            is_unique: false,
        });
    };

    // функция проверки массива на пустоту
    const isEmpty = (obj) => {
        for (var key in obj) {
            return;
        }
        return true;
    };

    return (
        <div className="menu__container">
            <div className="menu__block">
                <div className="menu__menu">
                    <SingleMenu title={"HOME"} updateConfig={updateConfig} />
                </div>
                <div className="menu__menu">
                    <Filter
                        title={"industry"}
                        filter={filters?.industries || []}
                        updateConfig={updateConfig}
                        config={config}
                    />
                </div>
                <div className="menu__menu">
                    <Filter
                        title={"company"}
                        filter={filters?.from_emails || []}
                        updateConfig={updateConfig}
                        config={config}
                    />
                </div>
                <div className="menu__menu">
                    <Filter
                        title={"country"}
                        filter={filters?.countries || []}
                        updateConfig={updateConfig}
                        config={config}
                    />
                </div>
            </div>
            <div className="menu__show__filters">
                <FieldFilter
                    config={config}
                    updateConfig={updateConfig}
                    title={"industry"}
                />
            </div>
            <div className="menu__show__filters">
                <FieldFilter
                    config={config}
                    updateConfig={updateConfig}
                    title={"company"}
                />
            </div>
            <div className="menu__show__filters">
                <FieldFilter
                    config={config}
                    updateConfig={updateConfig}
                    title={"country"}
                />
            </div>
            <div
                className={`${
                    isEmpty(email) ? "other__emails__hidden" : "other__emails"
                }`}
            >
                <Link to="/">
                    <div
                        className="other__emails__company"
                        onClick={() => onAllEmailCompany(email)}
                    >
                        <h5>
                            CHECK OTHER EMAILS OF THE
                            <span>&nbsp;{nameSender()}</span>
                        </h5>
                    </div>
                </Link>
            </div>
        </div>
    );
}
