import React from "react";
import "./email.css";
import { CloseOutlined } from "@ant-design/icons";
import BlockHtml from "../../utils/blockHtml/BlockHtml";
import { formatDate } from "../../utils/helper/Helper";
import { useParams, Link } from "react-router-dom";

export default function Email(props) {
    const { email, updateConfig } = { ...props };
    const { idEmail } = useParams();
    const item = email || {};

    return (
        <div className="email__body">
            <Link to="/" id={idEmail}
                className="email__body__close"
                onClick={() => updateConfig({ email_id: "" , is_unique: true, company: [] })}
            >
                <CloseOutlined className="email__body__close__icon" />
            </Link>
            <div className="email__header">
                <div className="email__header__row">
                    <p className="text__row"> From:</p>
                    <p
                        className="text__row__bold"
                        style={{ fontWeight: 700, fontSize: "0.8rem" }}
                    >
                        {" "}
                        {item.from_email}
                    </p>
                </div>
                <div className="email__header__row">
                    <p className="text__row"> Date:</p>
                    <p className="text__row__bold">
                        {" "}
                        {formatDate(item.received_at)}
                    </p>
                </div>
                <div className="email__header__row">
                    <p className="text__row"> Subject:</p>
                    <p className="text__row__bold" style={{ fontWeight: 700 }}>
                        {" "}
                        {item.subject}
                    </p>
                </div>
                <div className="email__header__row">
                    <p className="text__row"> To:</p>
                    <p className="text__row__bold"> {item.email}</p>
                </div>
            </div>
            <div className="email__body__content">
                <BlockHtml html={item} />
            </div>
        </div>
    );
}