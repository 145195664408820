import React from "react";
import "./layout.css";
import { Pagination } from "antd";
import { Spin } from "antd";
import Page from "./../page/Page";
import NoEmails from "../noEmails/NoEmails";

export default function Layout(props) {
    const {
        loading,
        emails,
        selectPage,
        page,
        defaultLimit,
        updateConfig,
        config,
    } = {
        ...props,
    };

    return (
        <div className="layout__container">
            <div className="layout__block">
                {loading ? (
                    <div className="layout__spin">
                        <Spin fullscreen={true} size="large" tip="Loading..." />
                    </div>
                ) : (
                    <>
                        {emails?.emails !== null ? (
                            <>
                                <Page
                                    emails={emails.emails}
                                    config={config}
                                    updateConfig={updateConfig}
                                />
                                <div className="layout__pagination">
                                    <Pagination
                                        current={page}
                                        total={emails.total}
                                        pageSize={defaultLimit}
                                        onChange={selectPage}
                                        showSizeChanger={false}
                                        hideOnSinglePage={true}
                                    />
                                </div>
                            </>
                        ) : (
                            <NoEmails data={"No emails"} />
                        )}
                    </>
                )}
            </div>
        </div>
    );
}
