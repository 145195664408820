import React from "react";
import "./header.css";
import LOGO from "../../../assets/icons/email kingdom.png";
import { Link } from "react-router-dom";
import { configHome } from "../../utils/helper/Helper";

export default function Header(props) {
    const { updateConfig } = { ...props };

    return (
        <div className="header__container">
            <div className="header__title">
                <Link to="/">
                    <img
                        src={LOGO}
                        alt="logo"
                        className="header__title__img"
                        onClick={() => updateConfig(configHome)}
                    />
                </Link>
                <p className="header__title__text__light">
                    Email Inspiration Tailored to Your Industry
                </p>
            </div>
        </div>
    );
}
