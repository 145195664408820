import React from "react";
import "./page.css";
import LayoutEmails from "../layoutEmails/LayoutEmails";
export default function Page(props) {
    const { emails, updateConfig } = { ...props };
    const items = emails;

    return (
        <div className="page__container">
            {items && (
                <div className="page__block">
                    {emails.map((item) => (
                        <div key={item.id}>
                            <LayoutEmails
                                item={item}
                                updateConfig={updateConfig}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
