import React, { useState } from "react";
import "./filter.css";
import { updateObj } from "../../utils/helper/Helper";
import { ConfigProvider, Select } from "antd";

export default function Filter(props) {
    const { filter, title, config, updateConfig } = { ...props };
    // показ в меню поля выбора фильтров
    const [showSelect, setShowSelect] = useState(false);
    // функция выбор фильтра
    const handleChangeFilter = (items) => {
        const newObj = updateObj(config, title, items);
        updateConfig({ ...newObj, is_unique: false, offset: "0" });
    };

    return (
        <div
            className={`${
                showSelect ? "filter__container__hover" : "filter__container"
            }`}
        >
            <div
                className={`${
                    showSelect ? "filter__title" : "filter__title__active"
                }`}
            >
                <h4
                    className="text__title"
                    onClick={() => setShowSelect(!showSelect)}
                >
                    {title}
                </h4>
            </div>
            {showSelect && (
                <div
                    className={`${
                        showSelect
                            ? "filter__block__active"
                            : "filter__block__no"
                    }`}
                >
                    <ConfigProvider
                        theme={{
                            components: {
                                Menu: {},
                            },
                            token: {
                                colorPrimary: "#d9d9d9",
                                fontSize: 12,
                            },
                        }}
                    >
                        <Select
                            onBlur={() => setShowSelect(false)}
                            mode={"multiple"}
                            value={
                                config[title].length === 0 ? [] : config[title]
                            }
                            defaultActiveFirstOption={false}
                            onChange={handleChangeFilter}
                            className="select"
                            options={filter.map((item) => ({
                                value: item.id,
                                label: item.name,
                            }))}
                        />
                    </ConfigProvider>
                </div>
            )}
        </div>
    );
}
