import React from "react";
import "./layoutEmails.css";
import BlockHtml from "../../utils/blockHtml/BlockHtml";
import { formatDate, transformFromString } from "../../utils/helper/Helper";
import { Link } from "react-router-dom";
const URL = process.env.REACT_APP_URL;

export default function LayoutEmails(props) {
    const { item, updateConfig } = { ...props };
    const nameEmail = transformFromString(item?.from_email);
    const date = formatDate(item.received_at);

    // функция открытия письма
    const onEmail = () => {
        updateConfig({
            email_id: item.id,
            company: [],
            industry: [],
            country: [],
        });
    };

    return (
        <>
            <Link to={`/email/${item.id}`}>
                <div className="main__item">
                    <div onClick={() => onEmail(item)}>
                        <div>
                            <div className="item__email__from__top">
                                <div>
                                    <h3 className="item__email__from__line__h3">
                                        {nameEmail}
                                    </h3>
                                </div>

                                <div>
                                    <h4
                                        className="item__email__from__line"
                                        style={{
                                            color: "#5f5f5f",
                                            fontSize: "0.7rem",
                                        }}
                                    >
                                        {date}
                                    </h4>
                                </div>
                            </div>
                            <div className="item__email__body">
                                {item.html_content === "" ? (
                                    <BlockHtml html={item} />
                                ) : (
                                    <img
                                        src={URL + item.printed_url}
                                        alt="png"
                                        className="item__image"
                                    />
                                )}
                            </div>
                            <div className="item__email__from__bottom">
                                <div>
                                    <p className="item__email__from__line">
                                        {item.subject}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </>
    );
}
