import React, { useState, useEffect } from "react";
import "./app.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Layout from "./components/pages/layout/Layout";
import NoEmails from "./components/pages/noEmails/NoEmails";
import Header from "./components/pages/header/Header";
import Menu from "./components/pages//menu/Menu";
import Email from "./components/pages/email/Email";
import { getFilter } from "./components/utils/getFilter/GetFilters";

import {
    Routes,
    Route,
    Outlet,
    useNavigate,
    useLocation,
} from "react-router-dom";
import {
    defaultLimit,
    configHome,
    formatObj,
} from "./components/utils/helper/Helper";

const URL_SERVER = process.env.REACT_APP_URL_SERVER;

export default function App() {
    const filters = getFilter();
    const navigate = useNavigate();
    const location = useLocation();
    const [emails, setEmails] = useState({}); // данные с сервера
    const [loading, setLoading] = useState(false); // загрузка данных
    const [config, setConfig] = useState(configHome);
    const [page, setPage] = useState(config.offset / config.limit + 1); // текущая страница
    const [email, setEmail] = useState(null);

    // функция первоначальной загрузки
    useEffect(() => {
        if (location.search !== "") {
            const params = new URLSearchParams(location.search.substring(1));
            updateConfig(formatObj(params));
            setPage(formatObj(params).offset / formatObj(params).limit + 1);
        } else {
            const id = location.pathname.split("/")[2];
            updateConfig({ email_id: id });
        }

        // eslint-disable-next-line
    }, [location.search]);

    // функция обновления конфигурации
    const updateConfig = (updates) => {
        const newConfig = { ...config, ...updates };
        setConfig(newConfig);
        const queryString = new URLSearchParams(newConfig).toString();

        if (newConfig.email_id) {
            getDataEmailByID(newConfig.email_id);
        } else {
            navigate(`?${queryString}`);
            getDataEmails(queryString);
            setEmail(null);
        }
        setPage(newConfig.offset / newConfig.limit + 1);
    };

    // функция выбора страницы
    const selectPage = (page) => {
        const OFFSET = (page - 1) * defaultLimit;
        updateConfig({ offset: OFFSET });
    };

    // функция получения данных emails с сервера
    // функция получения данных emails с сервера
    const getDataEmails = async (query) => {
        setLoading(true);
        try {
            const QUERY = URL_SERVER + "emails?" + query;
            const response = await fetch(`${QUERY}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setEmails(data);
        } catch (error) {
            console.error("Ошибка при получении данных:", error);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    // функция получения данных email с сервера по ID
    const getDataEmailByID = async (query) => {
        setLoading(true);
        try {
            const QUERY_ID = URL_SERVER + "email?id=" + query;
            const response = await fetch(`${QUERY_ID}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setEmail(data.email);
        } catch (error) {
            console.error("Ошибка при получении данных:", error);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    return (
        <HelmetProvider>
            <Helmet>
                <meta charset="utf-8" />
                <meta lang="en" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <meta name="theme-color" content="#fff" />
                <meta
                    name="Emails Kingdom"
                    content="The perfect solution for those working in Dating, iGaming, Financial Industries."
                />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Emails Kingdom" />
                <meta
                    property="og:description"
                    content="The perfect solution for those working in Dating, iGaming, Financial Industries."
                />
                <meta
                    property="og:url"
                    content="https://board.emailskingdom.com/"
                />
                <meta
                    property="og:image"
                    content="./assets/icons/seo-pic.png"
                />
                <title>Emails Kingdom</title>
            </Helmet>
            <div className="wrapper_app">
                <Header updateConfig={updateConfig} />
                <Menu
                    filters={filters}
                    updateConfig={updateConfig}
                    config={config}
                    email={email}
                />
                <Outlet />
                <Routes>
                    <Route
                        path="/email/:id/*"
                        element={
                            <Email email={email} updateConfig={updateConfig} />
                        }
                    />

                    <Route
                        path="/"
                        element={
                            <Layout
                                config={config}
                                selectPage={selectPage}
                                updateConfig={updateConfig}
                                emails={emails}
                                page={page}
                                loading={loading}
                                defaultLimit={defaultLimit}
                            />
                        }
                    />
                    <Route
                        path="*"
                        element={<NoEmails data={"Page not found"} />}
                    />
                </Routes>
            </div>
        </HelmetProvider>
    );
}
